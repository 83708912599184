import React from 'react';

const AgentingConsulting = () => {
  return (
    <div className="max-w-full mx-auto px-4 py-12 bg-gray-100">
      {/* Hero Section */}
      <section className="text-center mb-12">
        <h1 className=" font-bold text-gray-800 mb-4">
          <span className="block text-5xl mb-5 mr-6 md:mr-0">Welcome to CareAgentic</span>
          <span className="block text-3xl text-left ml-6 md:text-center">Transforming Healthcare with GenAI-Driven Consulting</span>
        </h1>
        <p className="text-xl text-blue-500 font-medium text-left mx-6 md:mx-20">
          At CareAgentic, we specialize in providing agentic, GenAI-powered consulting solutions tailored to the healthcare sector. Our innovative approach combines advanced AI with personalized strategies to optimize patient communication, internal knowledge, and healthcare productivity.
        </p>
      </section>

      {/* Our Key Solutions Section */}
      <section className="mb-12 mx-5">
        <h2 className="text-2xl font-semibold text-green-950 text-center mb-8">
          Our Key Solutions
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="p-6 border rounded-xl shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Presage
            </h3>
            <p className="text-gray-800">
              Elevate patient communication by delivering knowledge-driven insights, improving engagement, trust, and experience.
            </p>
          </div>
          <div className="p-6 border rounded-xl shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Plex
            </h3>
            <p className="text-gray-800">
              Build robust internal knowledge management systems to streamline information flow and enhance decision-making.
            </p>
          </div>
          <div className="p-6 border rounded-xl shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Progressive
            </h3>
            <p className="text-gray-600">
              Embed external knowledge to enhance doctors’ and staff’s expertise, driving continual learning.
            </p>
          </div>
          <div className="p-6 border rounded-xl shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Productivity
            </h3>
            <p className="text-gray-600">
              Automate non-core tasks, boosting efficiency and freeing time for critical healthcare duties.
            </p>
          </div>
          <div className="p-6 border rounded-xl shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Personalization
            </h3>
            <p className="text-gray-800">
              Personalize communication, delivering relevant content to patients for stronger relationships.
            </p>
          </div>
        </div>
      </section>

  
      <section className="text-start">
        <p className="text-lg text-blue-700 mx-auto ml-5">
          CareAgentic ensures that healthcare providers can deliver exceptional care by blending AI with human-centric solutions. Empower your practice today!
        </p>
      </section>
    </div>
  );
};

export default AgentingConsulting;
