import React from 'react';
import { Link } from 'react-router-dom';
import { FaBolt, FaMoneyBill, FaHeart } from 'react-icons/fa'; 

const GenRxPage: React.FC = () => {
  return (
      <div className="w-full h-full flex flex-col items-center justify-center bg-gray-100 mb-0">
      
      <div className="max-w-full bg-white p-8 rounded-lg space-y-8">
        <h1 className="text-4xl font-bold text-center text-gray-800">GenRx</h1>

        <p className="text-xl font-medium text-blue-500 text-left mx-10">
          GenRx is your ultimate solution for instant access to essential information on generic medicines.
          With just a WhatsApp message, you can receive detailed information, including the
          manufacturer, dosage, and cost-effective alternatives to branded drugs.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 text-center">Key Features:</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-6 md:mx-10">
          <div className="flex items-center bg-gray-100 p-6 rounded-xl shadow-lg border-2">
            <FaBolt className="text-blue-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Instant Access</h3>
              <p>Get information on generic medicine equivalents within seconds via WhatsApp.</p>
            </div>
          </div>

          <div className="flex items-center bg-gray-100 p-6 rounded-xl shadow-lg border">
            <FaMoneyBill className="text-green-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Cost Savings</h3>
              <p>Save up to 85% on medication costs by switching to generics, especially for chronic conditions like diabetes or hypertension.</p>
            </div>
          </div>

          <div className="flex items-center bg-gray-100 p-6 rounded-xl shadow-lg border">
            <FaHeart className="text-red-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Better Adherence</h3>
              <p>Affordable alternatives lead to improved adherence and long-term health benefits.</p>
            </div>
          </div>
        </div>

        <p className="text-lg text-blue-700 text-left mt-8 ml-8 md:ml-10">
          GenRx simplifies healthcare, making it affordable and accessible for everyone.
        </p>

        {/* <div className="mt-8 text-center">
          <Link to="/" className="inline-block px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition">
            Back to Home
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default GenRxPage;
