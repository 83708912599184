import React from 'react';

const Blog: React.FC = () => {
  return (
    <section className="flex items-center justify-center min-h-screen">
      <div className="p-8 text-center mx-4">
        <h1 className="text-3xl md:text-4xl font-bold">
          Coming Soon.......
        </h1>
        <p className="mt-4 text-lg md:text-xl">
          Bringing you closer to the knowledge that supports your wellness journey.
        </p>
      </div>
    </section>
  );
};

export default Blog;
