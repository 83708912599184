import React from 'react';
import { Link } from 'react-router-dom';
import { FaFileMedical, FaLanguage, FaComments } from 'react-icons/fa';

const AgentRxPage: React.FC = () => {
  return (
    <div className="h-full w-full flex flex-col items-center justify-center bg-gray-100">
      
      <div className="max-w-full bg-white p-8 rounded-lg space-y-8">
        <h1 className="text-4xl font-bold text-center text-gray-800">AgentRx Lab Analyzer</h1>

        <p className="text-xl text-blue-500 font-medium text-left mx-10">
          The AgentRx Lab Analyzer is a cutting-edge tool designed to simplify and interpret medical lab reports.
          By uploading your report via WhatsApp, the system instantly extracts key data, offering clear insights in an easy-to-understand format.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 text-center">Key Features:</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-6 md:mx-10">
          <div className="flex items-center bg-gray-100 p-6 rounded-lg shadow-lg border-2">
            <FaFileMedical className="text-blue-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Report Analysis</h3>
              <p>Identify normal ranges, areas of excellence, and both mild and severe anomalies.</p>
            </div>
          </div>

          <div className="flex items-center bg-gray-100 p-6 rounded-lg shadow-lg border">
            <FaLanguage className="text-green-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Multi-Format Insights</h3>
              <p>Receive feedback in both text and audio, with regional language support.</p>
            </div>
          </div>

          <div className="flex items-center bg-gray-100 p-6 rounded-lg shadow-lg border">
            <FaComments className="text-purple-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Interactive Support</h3>
              <p>Engage with real-time, conversational explanations of lab parameters, simplifying complex data.</p>
            </div>
          </div>
        </div>

        <p className="text-lg text-blue-700 text-left mt-8 ml-8 md:ml-10">
          AgentRx empowers patients to take charge of their health with actionable insights.
        </p>

        {/* <div className="mt-8 text-center">
          <Link to="/" className="inline-block px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition">
            Back to Home
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default AgentRxPage;
