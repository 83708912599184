import React from 'react';
const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-center py-4 text-white">
      <div className="font-bold text-lg">
        Carer AI
      </div>

      <div className="mt-2">
        Redmond, US | Gurgaon, India
      </div>
      <div className="mt-2">
        +91 9289228422
      </div>
      
      <div className="mt-4 text-sm">
        Copyright © 2024 Carer Healthcare - All Rights Reserved.
      </div>

    </footer>
  );
};

export default Footer;
